<template>
    <svg width="184" height="54" viewBox="0 0 184 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_503_3027)">
            <path d="M179.194 53.7553H108.756C106.323 53.7553 104.352 51.7294 104.352 49.2309V32.8684C104.352 30.3697 106.323 28.3438 108.756 28.3438H179.054C181.564 28.3438 183.598 30.4333 183.598 33.0114V49.2347C183.598 51.7294 181.626 53.7553 179.194 53.7553Z" fill="url(#paint0_linear_503_3027)" />
            <path d="M31.0586 32.4746H37.4389V34.6078H34.4034V49.6636H31.0586V32.4746Z" fill="#0070BA" />
            <path d="M41.2201 43.2042L41.7924 34.6039H41.9818L42.5541 43.2042H41.2201ZM37.5156 49.6595H40.7908L41.1002 45.0912H42.6778L42.9872 49.6595H46.2623L44.5185 32.4707H39.2596L37.5156 49.6595Z" fill="#0070BA" />
            <path d="M51.0517 43.6451V46.8824C51.0517 47.3237 51.3879 47.4466 51.5774 47.4466C51.8869 47.4466 52.1035 47.1766 52.1035 46.8824V42.8863C52.1035 42.3461 52.0066 41.7581 50.7657 41.7581H49.4043V39.8673H50.8155C51.7709 39.8673 52.1072 39.6448 52.1072 38.5921V35.1758C52.1072 34.8818 51.8905 34.6117 51.5815 34.6117C51.392 34.6117 51.0553 34.7349 51.0553 35.1758V37.9804H47.7107V35.5492C47.7107 34.3456 47.8539 32.4785 50.1969 32.4785H52.9655C55.309 32.4785 55.4523 34.3456 55.4523 35.5492V38.2981C55.4523 40.1652 54.1837 40.6777 53.1822 40.63V40.8008C55.4287 40.7531 55.4523 42.5209 55.4523 43.1326V46.5966C55.4523 47.8004 55.309 49.6671 52.9655 49.6671H50.1933C47.8498 49.6671 47.707 47.8004 47.707 46.5966V43.649H51.0517V43.6451Z" fill="#0070BA" />
            <path d="M57.6523 32.4746H65.3976V49.6636H62.0525V34.6078H61.0011V49.6636H57.6523V32.4746Z" fill="#0070BA" />
            <path d="M70.9503 41.9251V34.6039H71.3795C71.7393 34.6039 72.0021 34.9217 72.0021 35.4619V41.0631C72.0021 41.6034 71.7393 41.9211 71.3795 41.9211H70.9503V41.9251ZM67.6016 49.6595H70.9503V43.816H72.8605C75.2036 43.816 75.3468 41.949 75.3468 40.7453V35.5414C75.3468 34.3378 75.2036 32.4707 72.8605 32.4707H67.6016V49.6595Z" fill="#0070BA" />
            <path d="M81.9438 46.8826C81.9438 47.1768 81.7272 47.4468 81.4177 47.4468C81.2246 47.4468 80.892 47.3234 80.892 46.8826V35.1719C80.892 34.731 81.2282 34.6078 81.4177 34.6078C81.7272 34.6078 81.9438 34.8779 81.9438 35.1719V46.8826ZM77.5469 46.5926C77.5469 47.7964 77.6901 49.6636 80.0336 49.6636H82.8058C85.1493 49.6636 85.2921 47.7964 85.2921 46.5926V35.5453C85.2921 34.3417 85.1493 32.4746 82.8058 32.4746H80.0336C77.6901 32.4746 77.5469 34.3417 77.5469 35.5453V46.5926Z" fill="#0070BA" />
            <path d="M96.1887 32.4746H100.539V49.6636H97.1939V38.8822H97.0742L95.3534 49.6636H92.6773L90.9569 38.8822H90.8368V49.6636H87.4922V32.4746H91.8425L94.0192 44.5548L96.1887 32.4746Z" fill="#0070BA" />
            <path d="M26.7208 8.96977C25.696 4.36175 23.1285 0.611759 22.7727 0C22.2159 0.862022 20.1665 4.1671 19.1225 7.84164C17.9856 11.9333 17.8194 15.5641 18.2099 19.1432C18.6005 22.7185 20.0698 26.389 20.0698 26.389C20.8509 28.3156 22.0226 30.3773 22.7882 31.3942C23.9057 29.9006 26.4733 25.4555 27.2737 19.6398C27.7261 16.4102 27.7455 13.5778 26.7208 8.96977ZM22.7727 30.2462C22.2701 29.273 21.489 27.4178 21.4155 24.5537C21.3961 21.7809 22.475 19.4094 22.7921 18.9128C23.0744 19.4094 24.0024 21.4751 24.1145 24.3034C24.188 27.0762 23.2947 29.2531 22.7727 30.2462ZM26.423 16.7915C26.3844 18.5513 26.1794 20.4025 25.9203 21.5148C26.0131 19.604 25.7889 16.9067 25.3635 14.8014C24.9343 12.696 23.724 9.18033 22.7573 7.55162C21.864 9.10086 20.7659 12.1438 20.1897 14.7815C19.6097 17.4231 19.5942 20.6131 19.5942 21.5704C19.4395 20.764 19.0568 17.884 19.165 14.992C19.254 12.6046 19.7992 10.1536 20.0969 9.0254C21.2299 5.25951 22.5175 2.84824 22.7573 2.46689C22.997 2.84824 24.6172 5.8117 25.4563 8.93006C26.2915 12.0484 26.4578 15.0317 26.423 16.7915Z" fill="#0070BA" />
            <path d="M24.5117 32.4659H16.7201V37.8367C16.7278 37.8288 16.7317 37.8208 16.7394 37.8128C18.5762 35.926 21.5498 35.926 23.3865 37.8128C25.2232 39.6998 25.2232 42.7585 23.3865 44.6415C23.3787 44.6494 23.371 44.6574 23.3633 44.6653C23.3555 44.6733 23.3439 44.6812 23.3362 44.6892C21.5072 46.556 19.1137 47.4898 16.7201 47.4898C14.3188 47.4898 11.9136 46.5483 10.0808 44.6653C6.84811 41.3483 6.46919 36.212 8.92849 32.4619C9.261 31.9575 9.64382 31.4728 10.0808 31.0239C11.9136 29.141 14.3188 28.1995 16.7201 28.1995V15.2891C7.48613 15.2891 0 22.9797 0 32.458C0 41.9442 7.48613 49.6349 16.7201 49.6349C21.5343 49.6349 25.8728 47.5415 28.9238 44.2005V32.4619H24.5117V32.4659Z" fill="#0070BA" />
            <path d="M117.431 32.4746V35.5573H112.361V39.0927H114.175C116.545 39.0927 119.059 40.1693 119.059 44.3959C119.059 48.7655 116.502 49.6277 114.175 49.6277H108.34V32.4786H117.431V32.4746ZM112.361 46.5451H113.057C114.221 46.5451 115.033 46.1399 115.033 44.4436C115.033 42.7235 114.125 42.1753 113.127 42.1753H112.361V46.5451Z" fill="white" />
            <path d="M134.171 36.9441V44.6348C134.171 48.051 132.52 50.0332 128.753 50.0332C125.033 50.0332 123.336 48.051 123.336 44.6348V36.9203C123.336 34.223 124.987 32.0938 128.776 32.0938C132.566 32.0938 134.171 34.2667 134.171 36.9441ZM127.357 36.6581V45.3538C127.357 46.3827 128.053 46.883 128.753 46.883C129.426 46.883 130.149 46.3827 130.149 45.3538V36.6581C130.149 35.6769 129.43 35.2479 128.753 35.2479C128.057 35.2479 127.357 35.6769 127.357 36.6581Z" fill="white" />
            <path d="M142.682 39.0927H145.307V32.4746H149.329V49.6235H145.307V42.3382H142.682V49.6235H138.66V32.4746H142.682V39.0927Z" fill="white" />
            <path d="M157.68 32.4746L159.192 39.9508C159.308 40.5705 159.308 41.6947 159.4 43.4863H159.447C159.586 41.6709 159.702 40.5943 159.841 39.9508L161.4 32.4746H165.56L161.979 45.683C161.071 49.0039 160.119 49.8143 157.304 49.8143C156.562 49.8143 155.607 49.6477 155.282 49.3614V46.4457C155.468 46.5172 155.886 46.565 156.284 46.565C156.608 46.565 157.049 46.5411 157.239 45.9929L153.473 32.4746H157.68Z" fill="white" />
            <path d="M179.576 42.8432V44.4441C179.576 48.3373 177.855 50.0332 174.136 50.0332C170.439 50.0332 168.695 48.3611 168.695 44.4203V36.9441C168.695 34.2468 170.323 32.0938 174.136 32.0938C177.902 32.0938 179.576 34.2905 179.576 36.968V38.1398H175.555V36.5866C175.555 35.6769 174.72 35.2479 174.089 35.2479C173.416 35.2479 172.717 35.6292 172.717 36.5151V45.0678C172.717 46.3588 173.25 46.883 174.089 46.883C174.948 46.883 175.555 46.4065 175.555 45.1154V42.8472H179.576V42.8432Z" fill="white" />
        </g>
        <defs>
            <linearGradient id="paint0_linear_503_3027" x1="129.518" y1="66.7702" x2="159.588" y2="16.0729" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EF7D00" />
                <stop offset="1" stop-color="#E6007E" />
            </linearGradient>
            <clipPath id="clip0_503_3027">
                <rect width="184" height="54" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script
    setup
    lang="ts"
>

</script>
