<template>
    <TitleDefault
        class="swiper-discounts__title container"
        title="Нужно больше скидок?"
        subtitle="Добавьте к подписке всё, что вам необходимо из 60+ предложений наших партнёров"
    />
    
    <Swiper
        :space-between="0"
        :speed="2000"
        :loop="true"
        :modules="[Autoplay]"
        :autoplay="{
              delay: 0,
              disableOnInteraction: false
        }"
        slides-per-view="auto"
        class="swiper-discounts"
        @swiper="onSwiper"
    >
        <Swiper-slide
            v-for="index in 7"
            :key="index"
        >
            <div
                class="swiper-discounts__card"
            >
                <div
                    class="swiper-discounts__right"
                >
                    <img
                        :src="`img/pages/index/more-discounts/${index}.png`"
                        class="swiper-discounts__img"
                        alt="Логотип, нужно больше скидок"
                    />
                </div>
                
                <div
                    class="swiper-discounts__left"
                >
                    <img
                        :src="`img/pages/index/more-discounts/${index}${index}.png`"
                        class="swiper-discounts__img"
                        alt="Логотип, нужно больше скидок"
                    />
                </div>
            </div>
        </Swiper-slide>
    </Swiper>
</template>

<script
    setup
    lang="ts"
>
import { Autoplay } from 'swiper/modules';
import TitleDefault from "@/components/TitleDefault.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from "vue";

const slider = ref(null);

const onSwiper = (swiper: any) => {
    slider.value = swiper;
}
</script>
