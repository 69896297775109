export function redirectToWantUrl() {
    const wantUrl = process.env.VUE_APP_WANT_URL;

    if (wantUrl) {
        window.location.href = wantUrl;
    }
    else {
        console.error('WANT_URL is not defined in the environment variables');
    }
}

export function redirectToSubscribeUrl() {
    const subscribeUrl = process.env.VUE_APP_SUBSCRIBE;

    if (subscribeUrl) {
        window.location.href = subscribeUrl;
    }
    else {
        console.error('SUBSCRIBE is not defined in the environment variables');
    }
}