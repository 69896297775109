<template>
    <div
        class="container"
    >
        <TitleDefault
            class="home-menu__title"
            title="Выберите лучший тариф"
            subtitle="Чем дольше, тем выгоднее!"
        />
        
        <Swiper
            :space-between="10"
            :loop="true"
            class="swiper-best"
            slides-per-view="auto"
        >
            <Swiper-slide
                v-for="(item, index) in dataPlans"
                :key="index"
            >
                <div
                    class="swiper-best__card"
                >
                    <p
                        class="swiper-best__months"
                    >
                        {{ item.months }}
                    </p>
                    
                    <p
                        class="swiper-best__price"
                    >
                        {{ item.price }} ₽
                    </p>
                    
                    <p
                        v-if="item.text"
                        class="swiper-best__text"
                    >
                        {{ item.text }}
                    </p>
                    
                    <p
                        v-if="item.priceMonth"
                        class="swiper-best__price-month"
                    >
                        {{ item.priceMonth }} ₽ в месяц
                    </p>
                    
                    <p
                        v-if="item.sale"
                        class="swiper-best__sale"
                    >
                        Скидка ~{{ item.sale }}%
                    </p>
                    
                    <p
                        v-if="item.isOptimal"
                        class="swiper-best__chip optimal"
                    >
                        Оптимальный
                    </p>
                    
                    <p
                        v-if="item.isPopular"
                        class="swiper-best__chip popular"
                    >
                        Популярный
                    </p>
                </div>
            </Swiper-slide>
        </Swiper>
        
        <ButtonDefault
            class="w-full swiper-best__button"
            label="Попробовать за 1₽"
            @click="redirectToSubscribeUrl"
        />
    </div>

</template>

<script
    setup
    lang="ts"
>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { redirectToSubscribeUrl } from "@/utils/redirects";
import TitleDefault from "@/components/TitleDefault.vue";
import type { BestPlan } from "@/types/pages/index/best-plan";
import ButtonDefault from "@/components/ButtonDefault.vue";

const dataPlans = ref<BestPlan[]>([
    {
        months: "1 месяц",
        price: 199,
        text: "Чтобы попробоваться",
    },
    {
        months: "3 месяца",
        price: 570,
        priceMonth: 190,
        sale: 4,
        isPopular: true,
    },
    {
        months: "6 месяцев",
        price: 1080,
        priceMonth: 180,
        sale: 4,
        isOptimal: true,
    },
    {
        months: "12 месяцев",
        price: 2076,
        priceMonth: 173,
        sale: 4,
    },
])
</script>
<style lang="scss">
.swiper-best, .home-menu__title {
    display: none!important;
}
</style>
