<template>
    <div
        class="container"
    >
        <TitleDefault
            class="home-menu__title"
            title="Что включено"
        />
        
        <Swiper
            :space-between="10"
            :loop="true"
            class="swiper-included"
            slides-per-view="auto"
        >
            <Swiper-slide
                v-for="(item, index) in dataIncluded"
                :key="index"
            >
                <div
                    class="swiper-included__card"
                >
                    <p
                        class="swiper-included__title"
                    >
                        {{ item.title }}
                    </p>
                    
                    <p
                        v-if="item.price"
                        class="swiper-included__price"
                    >
                        {{ item.price }} ₽
                    </p>
                    
                    <img
                        :src="`img/pages/index/what-included/${item.image}.png`"
                        class="swiper-included__img"
                        alt="Логотип, все включено"
                    />
                </div>
                
                <p
                    class="swiper-included__description"
                >
                    {{ item.description }}
                </p>
            </Swiper-slide>
        </Swiper>
    </div>
</template>

<script
    setup
    lang="ts"
>
import { ref } from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import TitleDefault from "@/components/TitleDefault.vue";

import { WhatIncluded } from "@/types/pages/index/what-included";

const dataIncluded = ref<WhatIncluded[]>([
    {
        title: 'АЗС Газпромнефть',
        image: 5,
        description: "Повышенные бонусы в программе лояльности",
    },
    {
        title: 'Wink',
        price: 199,
        image: 2,
        description: "Бесплатный доступ к онлайн-кинотеатру",
    },
    {
        title: 'OZON',
        price: 199,
        image: 4,
        description: "Преимущества OZON Premium",
    },
    {
        title: 'Сервис “Пакет”',
        price: 199,
        image: 3,
        description: "Кешбэк в Пятерочке и Перекрестке",
    },
    {
        title: 'Premier',
        price: 299,
        image: 1,
        description: "Фильмы и сериалы без доплат",
    },
    {
        title: 'Лента',
        image: 6,
        description: "Скидка 13% на \n" +
            "5 покупок в месяц",
    },
    {
        title: 'Лента онлайн',
        image: 7,
        description: "Скидка 15% на \n" +
            "3 заказа в месяц",
    },
    {
        title: 'Газпромбанк мобайл',
        image: 8,
        description: "Скидка 30% на мобильную связь",
    },
])
</script>
