<template>
<span></span>
</template>

<script
    setup
    lang="ts"
>
import {useHead} from "@vueuse/head";

const props = defineProps({
  title: { type: String, default: '' }
})

useHead({
  title: `${props.title} | Штрафы`,
})

</script>
