<template>
    <button
        class="button-default"
        :class="[computedClass]"
        :style="{...computedStyle}"
        @click="handleClick"
    >
        <span
            v-if="loading"
            class="button-default__spinner"
        />
        
        <span
            class="d-flex justify-center items-center"
            :class="[loading ? 'opacity-0' : '']"
        >
             {{ label }}
            <slot />
        </span>
    </button>
</template>

<script
    lang="ts"
    setup
>
import {computed} from "vue";

interface Props {
    outline?:           boolean,
    flat?:              boolean,
    opacityButton?:     boolean,
    disable?:           boolean,
    loading?:           boolean,
    label?:             string | null,
    color?:             string | null,
    bgColor?:           string | null,
    borderColor?:       string | null,
}

interface Styles {
    color:           string | undefined,
    background:      string | undefined,
    borderColor:     string | undefined,
}

const props = withDefaults(
    defineProps<Props>(),
    {
        outline:         false,
        flat:            false,
        opacityButton:   false,
        disable:         false,
        loading:         false,
        label:           null,
        color:           null,
        bgColor:         null,
        borderColor:     null,
    }
);

const emit = defineEmits(['click'])

const computedClass = computed(() => {
    const classes = [];
    
    if (props.outline) {
        classes.push('outline')
    }
    
    if (props.flat) {
        classes.push('flat')
    }
    
    if (props.opacityButton) {
        classes.push('opacity-button')
    }
    
    if (props.disable || props.loading) {
        classes.push('custom-disable')
    }
    
    if (props.loading) {
        classes.push('loading')
    }
    
    return classes
})

const computedStyle = computed(() => {
    const styles = <Styles>{};
    
    if (props.color) {
        styles.color = props.color;
    }
    
    if (props.bgColor) {
        styles.background = props.bgColor;
        
        if (props.borderColor) {
            styles.borderColor = props.borderColor;
        }
        else if (props.bgColor.startsWith('#')) {
            styles.borderColor = props.bgColor;
        }
    }
    
    return styles;
})

function handleClick(event: any) {
    if (props.loading || props.disable) {
        event.stopPropagation();
        event.preventDefault();
        return;
    }
    
    emit('click')
}
</script>
