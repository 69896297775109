<template>
    <component
        :is="computedLayout"
    >
        <slot />
    </component>
</template>

<script
    setup
    lang="ts"
>
import { computed } from "vue";
import { useRoute } from "vue-router";
import AppLayoutDefault from "@/layouts/AppLayoutDefault.vue";

const route = useRoute();

const computedLayout = computed(() => route?.meta?.layoutComponent || AppLayoutDefault);
</script>
